import React, { Fragment } from "react"
import { graphql } from "gatsby"
import {
  ContentPage,
  TextOnlyHeader,
  ContentMetadata,
  ContentMetadataItem,
} from "../components/content-page"
import { PageRoot, ContentNode } from "../data/content"
import { NumericDate, TimeRange } from "../data/date"
import { useTranslation } from "react-i18next"
import {
  useLocalisedContent,
  useLocalisedContentViewer,
} from "../components/featured"
import SEO from "../components/seo"
import { Join } from "../data/list"
import { ArticleLanguageSelector } from "../components/content-page"
import { useTextDirection, TextDirectionProvider } from "../i18n"
import { Box } from "theme-ui"
import {
  ActionData,
  ActionSidebar,
  isActionSidebar,
} from "../components/actions"
import { ShareData, SocialSharer } from "../components/share"

const MovementAction: PageRoot<{ node: ContentNode }> = ({
  data: { node },
  pageContext: { locale, locales },
  location,
  location: { pathname },
}) => {
  const { t } = useTranslation()
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const topic = useLocalisedContent(node.frontmatter.topic)
  const dir = useTextDirection(locale)

  let anData = undefined

  if (
    localisedContent.anLink &&
    localisedContent.anIdentifier &&
    localisedContent.anSize &&
    localisedContent.anType
  ) {
    anData = new ActionData(
      localisedContent.anLink,
      localisedContent.anIdentifier,
      localisedContent.anSize,
      localisedContent.anType
    )
  }

  let shareData = undefined

  if (location?.href && localisedContent.title) {
    const shareMessage = localisedContent.title
    shareData = new ShareData(shareMessage, location?.href, shareMessage)
  }

  return (
    <TextDirectionProvider locale={locale}>
      <Box sx={{ textAlign: dir.defaultDir }}>
        <SEO
          path={pathname}
          lang={localisedContent.locale}
          title={localisedContent.title}
          description={
            localisedContent.shortAbstract || localisedContent.abstract
          }
          imageUrl={node.frontmatter.featuredImage?.publicURL}
          locales={locales}
        />
        <TextOnlyHeader
          themeColor={node.fields.themeColor}
          typeName={node.fields.typeName}
          location={node.frontmatter.location}
          topic={topic?.title}
          title={localisedContent.title}
          locale={localisedContent.locale}
          locales={locales}
        />
        <ContentPage
          {...localisedContent}
          content={node}
          anData={anData ? anData : undefined}
          meta={
            <ContentMetadata color={node.fields.themeColor}>
              {locales?.length && (
                <ArticleLanguageSelector locale={locale} locales={locales} />
              )}
              {!!node.frontmatter.authors?.length && (
                <ContentMetadataItem label={t(`Authors`)}>
                  <Join
                    value={node.frontmatter.authors.map(a =>
                      a === null ? " " : a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              {!!localisedContent.translators?.length && (
                <ContentMetadataItem
                  label={
                    <span>
                      {localisedContent.translators?.length > 1
                        ? t(`Translators`)
                        : t(`Translator`)}
                    </span>
                  }
                >
                  <Join
                    value={localisedContent.translators?.map(a =>
                      a === null ? " " : a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              {!!node.frontmatter.startDate && (
                <Fragment>
                  <ContentMetadataItem label={t(`Date`)}>
                    <NumericDate value={node.frontmatter.startDate} />
                  </ContentMetadataItem>
                  <ContentMetadataItem label={t(`Time`)}>
                    <TimeRange start={node.frontmatter.startDate} />
                  </ContentMetadataItem>
                </Fragment>
              )}
              {!!node.frontmatter.location && (
                <ContentMetadataItem label={t(`Location`)}>
                  {node.frontmatter.location}
                </ContentMetadataItem>
              )}
              {!!node.frontmatter.eventType && (
                <ContentMetadataItem label={t(`Type`)}>
                  {node.frontmatter.eventType.frontmatter.title}
                </ContentMetadataItem>
              )}
              <Box id="social-sharer">
                {shareData && (
                  <SocialSharer
                    shareData={shareData}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                )}
              </Box>
              <Box>
                {(node.frontmatter.pillar || node.fields.type) &&
                  anData &&
                  anData.isTruthy() &&
                  isActionSidebar(anData.anType) && (
                    <ActionSidebar
                      anData={anData}
                      type={
                        node.frontmatter.pillar
                          ? node.frontmatter.pillar
                          : node.fields.type
                      }
                    />
                  )}
              </Box>
            </ContentMetadata>
          }
        />
      </Box>
    </TextDirectionProvider>
  )
}

export const pageQuery = graphql`
  query MovementAction($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...ContentPageFragment
    }
  }
`

export default MovementAction
